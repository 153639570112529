///////////////////////////////////////////////////////////////////////////////////////////////////////

//お知らせ一覧

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-newslist {
  margin: 0;
  display: none;
  dl {
    width: 100%;
    display: flex;
    align-items: flex-start;
    @include mq-max(md) {
      flex-direction: column;
    }
    // dt,dd {}
    dt {
      width: 240px;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      padding-left: 35px;
      padding-top: 41px;
      @include mq-max(md) {
        width: 100%;
        padding: 0;
        margin-bottom: 10px;
      }
    }
    dd {
      font-size: 16px;
      color: $textColor;
      flex-grow: 1;
      padding: 40px 25px;
      @include mq-max(md) {
        padding: 0;
      }
    }
  }

  a {
    &[target="_blank"]{
      dd:after{
        @include fa('f35d');
        color: $keyColor;
        margin-left: 10px;
        -webkit-font-smoothing:antialiased;
      }
    }
    &[href$=".pdf"] {
      dd:after {
        @include fa('f1c1');
        font-weight: 400;
        color: $red;
        margin-left: 10px;
        -webkit-font-smoothing:antialiased;
      }
    }
  }

  &__wrap {
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item {
    border-bottom: 1px solid $bdrColor;
    &:first-child {
      border-top: 1px solid $bdrColor;
    }
    @include mq-max(md) {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &>a {
      display: flex;
      align-items: baseline;
      opacity: 1;
      transition: opacity $transition;
      @include mq-max(md) {
        padding: 15px 10px;
      }
      &:after {
        content: none;
      }
      &:hover {
        opacity: .7;
      }
    }
  }

  &__date {
    display: block;
    margin-right: 15px;
    font-size: 16px;
    font-weight: 600;
    color: $textColor;
  }
  .c-label {
    display: block;
    font-size: 12px;
    max-width: 100px;
    width: 100%;
    flex: 0 0 100%;
    padding: 5px;
    margin-right: 0;
    color: $black;
    text-align: center;
    line-height: 1;
    background-color: $gray-dark;
    border-color: $gray-dark;
    @include mq-max(md) {
      margin-right: 0;
    }
  }

  //Show
  &.is-showNewsList {
    display: block;
  }

  //カテゴリ別
  &--all {}
  &--logicom {}
  &--logicom-eye {}

}