.cc {
  // IEで「許可する」ボタンが被らないよう調整
  &-theme-classic {
    .cc-btn {
      &:last-child {
        min-width: auto;
      }
    }
  }
  // IEでタブが右端に表示されるよう調整
  &-revoke {
    &.cc-right {
      left: auto;
    }
  }
}