/*
モジュール系おまとめファイル
*/

// ヘッダー
@import "header";

// フッター
@import "footer";

// グローバルナビゲーション
@import "navigation";

 // ページトップに戻る
@import "pageTop";

 // ボックス
@import "box";

 // ボタン
@import "button";

//テーブル
@import "table";

//リスト
@import "list";

//見出し
@import "heading";

//アコーディオン
@import "accordion";

//タブ
@import "tab";

//テキストの装飾
@import "txt";

//沿革
@import "timeline";

// ラベル
@import "label";

//お知らせ
@import "newslist";

// クラス付与型ホバーアクション
@import "hover";

//CV　お問い合わせ
@import "cvContact";

//カード
@import "card";

//リンク
@import "link";

//スライダーカード
@import "sliderCard";

//モーダル
@import "modal";

//クリップマスク
@import "clip";

//電話番号
@import "tel";

//フロー
@import "flow";

//スライダー
@import "slider";

//GDPR表記
@import "gdpr";