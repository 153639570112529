///////////////////////////////////////////////////////////////////////////////////////////////////////

//  見出しクラス

///////////////////////////////////////////////////////////////////////////////////////////////////////

//通常
.c-ttl {
  //h2
  &-2 {
    font-size: 32px;
    position: relative;
    padding-bottom: 17px;
    margin-bottom: 15px;
    @include mq-max(sm) {
      font-size: 23px;
    }
    &:after {
      content: '';
      width: 58px;
      height: 5px;
      background: linear-gradient(90deg, $green 0%, $green 33%, $red 33%, $red 66%, $blue 66%, $blue 100%);
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    //レーザー
    &--laser {
      padding-bottom: 15px;
      &:after {
        content: '';
        width: 93px;
        height: 4px;
        background: linear-gradient(
                                    90deg,
                                    $red 0%, $red 13%,
                                    transparent 13%, transparent 18%,
                                    $red 18%, $red 71%,
                                    transparent 71%, transparent 76%,
                                    $red 76%, $red 91%,
                                    transparent 91%, transparent 96%,
                                    $red 96%, $red 100%,
                                  );
        display: block;
        position: absolute;
        bottom: auto;
        bottom: 0;
        left: 0;
      }
    }
  }

  //mb大き目
  &--mbLarge {
    margin-bottom: 40px;
    @include mq-max(sm) {
      margin-bottom: 25px;
    }
  }

  //真ん中寄せ
  &--center {
    text-align: center;
    &:after {
      left: 50%;
      transform: translateX(-50%);
    }
  }


  //h3
  &-3 {
    font-size: 24px;
    margin-bottom: 10px;
    @include mq-max(sm) {
      font-size: 20px;
    }
    &--vline {
      position: relative;
      padding-left: 15px;
      &:before {
        content: '';
        width: 3px;
        height: 25px;
        background: linear-gradient(180deg, $green 0%, $green 33%, $red 33%, $red 66%, $blue 66%, $blue 100%);
        display: block;
        position: absolute;
        top: .3em;
        left: 0;
        @include mq-max(sm) {
          top: .2em;
        }
      }
    }
    &__subttl {
      display: block;
      font-size: 16px;
      @include mq-max(sm) {
        font-size: 14px;
      }
    }

    //三角付き
    &--triangle {
      padding-left: 35px;
      position: relative;
      @include mq-max(sm) {
        padding-left: 30px;
      }
      &:before {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 23.5px 23.5px;
        border-color: transparent transparent $keyColor-recruit transparent;
        position: absolute;
        top: .3em;
        left: 0;
        @include mq-max(sm) {
          border-width: 0 0 20px 20px;
        }
      }
    }
  }

  //h4
  &-4 {
    font-size: 21px;
    margin-bottom: 10px;
    @include mq-max(sm) {
      font-size: 18px;
    }
  }
}


//見出し（一番使うやつ）
.c-topHeading {
  font-size: 70px;
  font-weight: 600;
  font-family: $roboto;
  font-style: italic;
  letter-spacing: 0.06em;
  line-height: 1;
  margin-bottom: 30px;
  @include mq-max(sm) {
    font-size: 50px;
    margin-bottom: 20px;
  }
  @include mq-max(xs) {
    font-size: 36px;
    margin-bottom: 20px;
  }
  &>small {
    display: block;
    font-size: 18px;
    font-family: $sanserif;
    font-style: normal;
    letter-spacing: 0.06em;
    line-height: 1.5;
    @include mq-max(xs) {
      font-size: 16px;
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    @include mq-max(md) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 30px;
    }
    .c-topHeading {
      margin-right: 65px;
      @include mq-max(md) {
        margin: 0 0 20px;
      }
    }
    &>p {
      flex-grow: 1;
      font-size: 20px;
      font-weight: 600;
      line-height: (28 / 20);
      @include mq-max(md) {
        margin-bottom: 30px;
      }
      @include mq-max(sm) {
        font-size: 16px;
      }
    }
  }

  //ライン付き
  &--line {
    &>small {
      position: relative;
      padding-bottom: 17px;
      &:after {
        content: '';
        width: 58px;
        height: 5px;
        background: linear-gradient(90deg, $green 0%, $green 33%, $red 33%, $red 66%, $blue 66%, $blue 100%);
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }

  //レーザー
  &--laser {
    &>small {
      position: relative;
      margin-top: 10px;
      padding-bottom: 0;
      &:after {
        content: '';
        width: 93px;
        height: 4px;
        background: linear-gradient(
                                    90deg,
                                    $red 0%, $red 13%,
                                    transparent 13%, transparent 18%,
                                    $red 18%, $red 71%,
                                    transparent 71%, transparent 76%,
                                    $red 76%, $red 91%,
                                    transparent 91%, transparent 96%,
                                    $red 96%, $red 100%,
                                  );
        display: block;
        position: absolute;
        bottom: auto;
        top: -8px;
        left: 0;
      }
    }
  }

  //真ん中寄せ
  &--center {
    text-align: center;
    &>small {
      &:after {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  //mb大き目
  &--mbLarge {
    margin-bottom: 52px;
    @include mq-max(sm) {
      margin-bottom: 35px;
    }
  }

  //白
  &--white {
    color: $white;
  }
}


//四隅三角見出し
.c-rectHeading {
  font-size: 32px;
  text-align: center;
  padding: 15.5px 15px;
  position: relative;
  @include mq-max(sm) {
    font-size: 20px;
  }
  @include mq-max(xs) {
    text-align: left;
  }
  span {
    display: inline-block;
    &:before,&:after {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
    }
  }
  //1層目
  &>span {
    &:before {
      border-width: 26px 26px 0 0;
      border-color: $green transparent transparent transparent;
      top: 0;
      left: 0;
    }
    &:after {
      border-width: 0 26px 26px 0;
      border-color: transparent $red transparent transparent;
      top: 0;
      right: 0;
    }
    //2層目
    &>span {
      &:before {
        border-width: 26px 0 0 26px;
        border-color: transparent transparent transparent $red;
        bottom: 0;
        left: 0;
      }
      &:after {
        border-width: 0 0 26px 26px;
        border-color: transparent transparent $blue transparent;
        bottom: 0;
        right: 0;
      }
    }
  }
  // トップページのメインビジュアル用
  &--mainvisual {
    padding: 4vw 3vw;
    margin-bottom: 0;
    @include mq-max(xs) {
      padding: 7vw 7vw;
    }
    //1層目
    &>span {
      &:before {
        border-width: 5.7vw 5.7vw 0 0;
        border-color: $green transparent transparent transparent;
        top: 0;
        left: 0;
        @include mq-max(xs) {
          border-width: 8.7vw 8.7vw 0 0;
        }
      }
      &:after {
        border-width: 0 5.7vw 5.7vw 0;
        border-color: transparent $red transparent transparent;
        top: 0;
        right: 0;
        @include mq-max(xs) {
          border-width: 0 8.7vw 8.7vw 0;
        }
      }
      //2層目
      &>span {
        &:before {
          border-width: 5.7vw 0 0 5.7vw;
          border-color: transparent transparent transparent $red;
          bottom: 0;
          left: 0;
          @include mq-max(xs) {
            border-width: 8.7vw 0 0 8.7vw;
          }
        }
        &:after {
          border-width: 0 0 5.7vw 5.7vw;
          border-color: transparent transparent $blue transparent;
          bottom: 0;
          right: 0;
          @include mq-max(xs) {
            border-width: 0 0 8.7vw 8.7vw;
          }
        }
      }
    }
    .heading {
      height: auto;
      margin-bottom: 10px;
      width: 23vw;
      max-width: 95%;
      @include mq-max(xs) {
        width: 100%;
        max-width: 100%;
      }
    }
    .desc {
      text-align: left;
      font-size: 1vw;
      @include mq-max(xs) {
        font-size: 3vw;
      }
    }
  }
}


//見出しの装飾（共通設定）
.c-laserHeading,
.c-lineHeading {
  font-size: 32px;
  text-align: center;
  padding: 0;
  margin-bottom: 40px;
  position: relative;
  @include mq-max(sm) {
    font-size: 20px;
    margin-bottom: 30px;
  }
  @include mq-max(xs) {
    text-align: left;
  }
  &>span {
    display: inline-block;
    padding: 32px 60px;
    position: relative;
    @include mq-max(sm) {
      padding: 20px 45px;
    }
    &:before,&:after {
      content: '';
      display: inline-block;
      width: 118.7px;
      height: 87.8px;
      position: absolute;
      @include mq-max(sm) {
        width: 118.7px * .6;
        height: 87.8px * .6;
      }
    }
    &:before {
      top: -33px;
      left: 0;
      @include mq-max(sm) {
        top: -10px;
        left: -5px;
      }
    }
    &:after {
      bottom: -33px;
      right: 0;
      @include mq-max(sm) {
        bottom: -10px;
        right: -5px;
      }
    }
  }
}
//左上と右下に赤線の装飾がある見出し
.c-laserHeading {
  &>span {
    &:before {
      background: url('/inc/image/common/ico/ico_laser-top-left.svg')no-repeat center / contain;
    }
    &:after {
      background: url('/inc/image/common/ico/ico_laser-bottom-right.svg')no-repeat center / contain;
    }
  }
}
//左上と右下に3色の装飾がある見出し
.c-lineHeading {
  &>span {
    &:before {
      background: url('/inc/image/common/ico/ico_line-top-left.svg')no-repeat center / contain;
    }
    &:after {
      background: url('/inc/image/common/ico/ico_line-bottom-right.svg')no-repeat center / contain;
    }
  }
}