//スライダーカード

//scss関数
//単位をトル
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

//比率調整用変数
$imgWidth: 821.5;
$descWidth: 694.5;
$contentWidth-num : strip-unit($contentWidth);// $contentWidthの「px」なしの値

.c-sliderCard {
  position: relative;
  &__inner {
    display: flex;
    align-items: center;
    @include mq-max(content) {
      margin-bottom: 40px;
    }
    @include mq-max(md) {
      flex-direction: column;
    }
  }

  //コントローラー
  &__control {
    display: flex;
    align-items: center;
    position: absolute;
    top: 25px;
    right: 360px;
    @include mq-max(content) {
      position: static;
      justify-content: flex-end;
      margin-bottom: 20px;
    }
    @include mq-max(md) {
      justify-content: flex-start;
      padding-left: 5px;
    }
    &-inner {
      display: flex;
      align-items: center;
      margin-right: 33px;
    }
    &-arrow {
      &>a {
        color: $keyColor;
        display: block;
        line-height: 1;
        font-size: 24px;
        padding: 4px 0;
      }
      &--prev {
        border-right: 2px solid $bdrColor;
        &>a {
          padding-right: 25px;
          padding-left: 5px;
        }
      }
      &--next {
        &>a {
          padding-right: 5px;
          padding-left: 25px;
        }
      }
    }
    &-index {
      display: flex;
      align-items: center;
      &-num {
        display: inline-block;
        font-size: 25px;
        font-weight: bold;
        line-height: 1;
        color: $keyColor;
        &--current {
          &:after {
            content: '-';
            display: inline-block;
            margin: 0 5px;
            color: inherit;
          }
        }
        // &--whole {}
      }
    }
  }

  //ボタン
  &__btn {
    text-align: right;
    @include mq-max(md) {
      text-align: center;
    }
  }

  //画像スライダー
  &__img {
    flex-basis: calc(#{$imgWidth} / #{$contentWidth-num} * 100%);
    max-width: calc(#{$imgWidth} / #{$contentWidth-num} * 100%);
    width: 100%;
    overflow: hidden;
    clip-path: $commonClip;
    position: relative;
    z-index: 1;
    @include mq-max(md) {
      flex-basis: 100%;
      max-width: 100%;
    }
    @include mq-max(xs) {
      clip-path: $commonClip-xs;
    }
    &-list {
      width: 100%;
    }
    &-item {
      &>a {
        overflow: hidden;
        display: block;
        line-height: 1;
        img {
          transition: all $transition;
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  //文章スライダー
  &__desc {
    position: relative;
    z-index: 2;
    width: 100%;
    flex-basis: calc(#{$descWidth} / #{$contentWidth-num} * 100%);
    max-width: calc(#{$descWidth} / #{$contentWidth-num} * 100%);
    margin-left: calc( -1 * ( #{$imgWidth}px + #{$descWidth}px - #{$contentWidth} ) );
    @include mq-max(md) {
      flex-basis: calc(316 / 341 * 100%);
      max-width: calc(316 / 341 * 100%);
      margin-left: 0;
      margin-top: -30px;
    }
    &-inner {
      box-shadow: $box-shadow;
      background-color: $white;
      overflow: hidden;
    }
    // &-list {}
    &-item {
      &>a {
        display: block;
        padding: 42px 0 66px 60px;
        color: $textColor;
        @include mq-max(sm) {
          padding: 25px 0 30px 30px;
        }
        &:hover {
          color: $textColor;
          .c-readmore {
            &:after {
              right: -25px;
            }
          }
        }
      }
    }
    &-num {
      font-size: 60px;
      font-weight: 600;
      color: $keyColor;
      line-height: 1;
      @include mq-max(xs) {
        font-size: 50px;
      }
    }
    &-body {
      padding-right: 60px;
      padding-bottom: 35px;
      margin-bottom: 35px;
      position: relative;
      @include mq-max(xs) {
        padding-right: 25px;
        padding-bottom: 20px;
      }
      &:before,&:after {
        content: '';
        height: 1px;
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: 0;
      }
      &:before {
        background-color: $gray;
        width: 100%;
        z-index: 1;
      }
      &:after {
        background-color: $keyColor;
        width: 0%;
        z-index: 2;
      }
      &.is-lineAnimated {
        &:after {
          animation-name: progressLine;
          animation-duration: 5s;
          animation-delay: 0s;
          animation-timing-function: linear;
          animation-fill-mode: forwards;
        }
      }
    }
    &-title {
      font-size: 24px;
      margin-bottom: 5px;
      @include mq-max(xs) {
        font-size: 22px;
      }
    }
    &-txt {
      @include mq-max(xs) {
        height: auto !important;
      }
    }
  }

}



//アニメーション
@keyframes progressLine {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}