.l-f {
  background-color:#EFEFEF;
  &__inner {
    max-width: $contentWidth;
    width: 100%;
    margin: 0 auto;
    padding: 86px 0 96px;
    display: flex;
    justify-content: space-between;
    @include mq-max(content) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @include mq-max(md) {
      flex-direction: column;
      align-items: center;
      padding-top: 40px;
      padding-bottom: 10px;
    }
  }

  //左側
  &__company {
    max-width: 410px;
    width: 100%;
    @include mq-max(md) {
      margin-bottom: 40px;
    }
    &-logo {
      margin-bottom: 25px;
      @include mq-max(md) {
        margin-bottom: 34px;
      }
      img {
        display: block;
        line-height: 1;
        max-width: 100%;
        width: auto;
        @include mq-max(md) {
          margin: 0 auto;
        }
      }
    }
  }
  &__info {
    font-size: 16px;
    line-height: 1.75;
  }

  //右側
  &__links {
    &-top {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 18px;
      @include mq-max(md) {
        justify-content: center;
        margin-bottom: 34px;
      }
      &>li {
        margin-right: 44px;
        &:last-child {
          margin-right: 0;
        }
        &>a {
          font-size: 15px;
          color: $black;
        }
      }
    }
    &-bottom {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 34px;
      @include mq-max(md) {
        justify-content: center;
      }
      &>li {
        margin-right: 16px;
        @include mq-max(md) {
          margin-right: 10px;
        }
        &:last-child {
          margin-right: 0;
        }
        img {
          display: block;
          line-height: 1;
          max-width: 100%;
        }
      }
    }
  }

  //Copyright
  &__copyright {
    font-size: 16px;
    font-weight: 400;
    text-align: right;
    @include mq-max(md) {
      text-align: center;
    }
    @include mq-max(xs) {
      font-size: 12px;
    }
  }

  //採用サイト マイナビバナー
  &__bnr {
    max-width: 200px;
    margin-left: auto;
    margin-bottom: 10px;
    @include mq-max(md) {
      margin-right: auto;
    }
    @include mq-max(xs) {
      max-width: 100%;
      margin-bottom: 40px;
    }
    a {
      &:after {
        content: none;
      }
    }
  }
}
