//フロー
.c-flow {
  &__list {
    counter-reset: process;
  }
  &__item {
    margin-bottom: 65px;
    position: relative;
    &:after {
      @include fa('f0ab');
      font-size: 32px;
      line-height: 1;
      color: $keyColor;
      position: absolute;
      bottom: -47px;
      left: 50%;
      transform: translateX(-50%);
    }
    &:last-child {
      margin-bottom: 0;
      &:after {
        content: none;
      }
    }
  }
  &__inner {
    padding: 35px 40px 40px;
    background-color: $white;
    @include mq-max(sm) {
      padding: 15px 20px 20px;
    }
  }
  &__title {
    font-size: 32px;
    margin-bottom: 14px;
    padding-bottom: 15px;
    border-bottom: 1px solid $bdrColor;
    display: flex;
    align-items: center;
    @include mq-max(sm) {
      font-size: 28px;
    }
    &:before {
      content: counter(process,decimal-leading-zero);
      counter-increment: process;
      font-size: 41px;
      font-weight: 600;
      color: $keyColor;
      line-height: 1;
      margin-right: 20px;
      padding: 10px 20px 10px 0;
      border-right: 1px solid $bdrColor;
      flex-shrink: 0;
      @include mq-max(xs) {
        font-size: 36px;
      }
    }
  }
}


//スケジュール
.c-schedule {
  $boxSize: 125px;
  $boxSize-xs: 70px;
  &__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    position: relative;
    @include mq-max(xs) {
      margin-bottom: 40px;
    }
    &:last-child {
      margin-bottom: 0;
      &:before {
        content: none;
      }
    }
    &:before {
      content: '';
      width: 3px;
      height: 100%;
      display: inline-block;
      background-color: $keyColor-recruit;
      position: absolute;
      top: $boxSize;
      left: $boxSize/2;
      z-index: 1;
      @include mq-max(xs) {
        top: $boxSize-xs;
        left: $boxSize-xs/2;
      }
    }
  }
  &__time {
    width: $boxSize;
    flex: 0 0 $boxSize;
    height: $boxSize;
    background-color: $white;
    border: 3px solid $keyColor-recruit;
    font-size: 24px;
    font-weight: 600;
    color: $keyColor-recruit;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    z-index: 2;
    @include mq-max(xs) {
      width: $boxSize-xs;
      flex: 0 0 $boxSize-xs;
      height: $boxSize-xs;
      font-size: 18px;
      margin-right: 20px;
    }
  }
  &__body {
    flex-grow: 1;
    padding-top: 1em;
  }
  &__title {
    font-size: 21px;
    margin-bottom: 2px;
  }
  &__txt {
    font-size: 16px;
    line-height: 1.5;
  }
}