//モーダル

.modalTrigger {
  display: block;
  line-height: 1;
  transition: all $transition !important;
  &:hover {
    opacity: .7;
  }

  &--icon {
    position: relative;
    &:before {
      content: '';
      width: 47px;
      height: 47px;
      display: inline-block;
      background-color: $keyColor;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
    }
    &:after {
      @include fa('f00e');
      color: $white;
      line-height: 1;
      font-size: 26px;
      position: absolute;
      bottom: 10px;
      right: 10px;
      z-index: 2;
    }
  }
}


//表示
.js-modal {
  max-width: 945px;
  margin: 0 auto;
  &__inner {
    background-color: $white;
    padding: 55px 60px 30px;
    position: relative;
    @include mq-max(sm) {
      padding: 60px 15px 20px;
    }
  }

  //閉じるボタン
  &__close {
    background-color: $keyColor;
    color: $white;
    position: absolute;
    top: 0;
    right: 0;
    line-height: 1;
    a {
      color: $white;
      line-height: 1;
      display: block;
      font-size: 13px;
      padding: 16px 18px;
      &:hover {
        color: $white;
      }
    }
  }
}

//調整
.mfp-bg {
  z-index: 10009 !important;
}
.mfp-wrap {
  z-index: 10010 !important;
}
.mfp-container {
  padding: 15px 10px !important;
}