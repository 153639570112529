//カード

//=カード　サムネクリップ
$gridTune: 21px;
.c-clipCard {
  //gridカスタム
  &__wrap {
    @include gridTune($gridTune);
    margin-bottom: 19px;
    @include mq-max(content) {
      margin-bottom: 0;
    }
    &:last-child {
      margin-bottom: calc(-1 * #{$gridTune} - 20px);
      @include mq-max(content) {
        margin-bottom: calc(-1 * #{$contentPadding} - 20px);
      }
    }
  }
  &__item {
    margin-bottom: 20px;
    &>a {
      display: block;

      //全体ホバー
      &:hover {
        .c-clipCard__thumb {
          img {
            transform: scale(1.1);
          }
        }
        .c-readmore {
          &:after {
            right: -25px;
          }
        }
      }
    }
  }

  //サムネイル
  &__thumb {
    overflow: hidden;
    clip-path: $commonClip;
    @include mq-max(xs) {
      clip-path: $commonClip-xs;
    }
    img {
      width: 100%;
      display: block;
      transition: all $transition;
    }
  }

  //body部分
  &__body {
    background-color: $white;
    padding: 20px 25px 28px;
    color: $textColor;
    box-shadow: $box-shadow;
    max-width: calc(391 / 464 * 100%);
    width: 100%;
    margin: -78px auto 0;
    position: relative;
    z-index: 2;
  }
  &__heading {
    margin-bottom: 4px;
    font-size: 24px;
    font-weight: 500;
  }
  &__txt {
    line-height: (28/16);
    margin-bottom: 10px;
  }
}


//紹介用カード
.c-introCard {
  // &__wrap {}
  &__item {
    margin-bottom: 20px;
    &>a {
      display: block;

      //全体ホバー
      &:hover {
        .c-introCard__thumb {
          img {
            transform: scale(1.1);
          }
        }
        .c-readmore {
          &:before {
            animation-name: readmoreStream2;
            animation-duration: .5s;
            animation-timing-function: ease;
            animation-delay: .5s;
            animation-direction: alternate;
            animation-fill-mode: forwards;
          }
          &:after {
            animation-name: readmoreStream1;
            animation-duration: .5s;
            animation-timing-function: ease;
            animation-delay: 0s;
            animation-direction: alternate;
            animation-fill-mode: forwards;
          }
        }
      }
    }
  }
  &__thumb {
    overflow: hidden;
    clip-path: $commonClip;
    margin-bottom: 15px;
    @include mq-max(xs) {
      clip-path: $commonClip-xs;
    }
    img {
      width: 100%;
      display: block;
      transition: all $transition;
    }
  }
  &__body {
    position: relative;
  }
  &__txt {
    color: $textColor;
    line-height: 1.5;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  &__info {
    color: $textColor;
    padding-right: 98px;
  }
  &__branch {
    font-size: 12px;
    font-weight: 600;
    background-color: $gray-l;
    padding: 2px 5px;
    display: inline-block;
  }
  &__year {
    font-size: 14px;
    font-weight: 500;
    padding: 0 2px;
    margin-bottom: -2px;
  }
  &__name {
    font-size: 22px;
    font-weight: 500;
  }

  //パーツ調整
  .c-readmore {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

@keyframes readmoreStream1 {
  0% {
    left: 0%;
  }
  100% {
    left: 100%;
  }
}
@keyframes readmoreStream2 {
  0% {
    left: -100%;
  }
  100% {
    left: 0%;
  }
}

// 画像+タイトル+テキストのカード
.c-card {
  &__img {
    margin-bottom: 10px;
    @include mq-max(xs) {
      margin-bottom: 5px;
    }
  }
  &__txt {
    line-height: 1.5;
  }
}


//モーダルカード
.c-modalCard {
  &__link {
    display: block;
    box-shadow: $box-shadow;
    transition: all $transition !important;
    &:hover {
      box-shadow: $box-shadow-hover;
    }
    &.modalTrigger {
      &:hover {
        opacity: 1;
      }
    }
  }
  &__inner {
    padding-top: 20px;
    background-color: $white;
  }
  &__thumb {
    max-width: 100%;
    width: 100%;
    &>img {
      display: block;
      width: 100%;
      line-height: 1;
    }
  }
  &__body {
    padding: 28px 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__txt {
    font-size: 24px;
    font-weight: 600;
    color: $textColor;
    // text-align: left;
    display: inline-block;
    line-height: 1.2;
  }

  //装飾
  &__strong {
    font-size: 60px;
    line-height: 1;
  }
  &__small {
    font-size: .7em;
  }
}