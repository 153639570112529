//スライダー

.c-endlessSlider {
  margin-bottom: 35px;
  &:last-child {
    margin-bottom: 0;
  }
  &__item {
    line-height: 1;
  }
  &__img {
    img {
      width: 100%;
      display: block;
      line-height: 1;
    }
  }

  //slickカスタム
  .slick-track,
  .slick-list,
  .slick-slide,.slick-slide > div {
    height: 100%;
  }
}