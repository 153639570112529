//タブ

$bdr-width-tab: 2px;

///////////////////////////////////////////////////////////////////////////////////////////////////

.c-tab {
  overflow: hidden;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  //注意書き表示
  &.add_attention {
    &:before {
      @include mq-max(md) {
        content: 'スライドでタブを移動できます→';
        display: block;
        font-size: 12px;
        padding-bottom: 20px;
      }
    }
  }

  &__select {
    display: flex;
    align-items: baseline;
    padding-top: 10px;
    @include mq-max(md) {
      overflow-x: scroll;
    }
  }

  &__item {
    max-width: 100%;
    width: 100%;
    position: relative;
    margin-right: 18px;
    cursor: pointer;
    flex: 0 1 auto; //IE10バグ対策
    border-bottom: $bdr-width-tab solid $bdrColor;
    @include mq-max(xs) {
      margin-right: 9px;
    }
    &:after {
      content: '';
      width: calc(18px + (#{$bdr-width-tab} * 2));
      height: $bdr-width-tab;
      background-color: $bdrColor;
      position: absolute;
      bottom: -1 * $bdr-width-tab;
      right: -18px - $bdr-width-tab;
      @include mq-max(xs) {
        width: calc(9px + (#{$bdr-width-tab} * 2));
        right: -9px - $bdr-width-tab;
      }
    }
    &:last-child {
      margin-right: 0;
      &:after {
        content: none;
      }
    }
    @include mq-max(md) {
      min-width: calc(100% / 5);
    }
    @include mq-max(sm) {
      min-width: calc((100% / 3) - 18px);
    }
    @include mq-max(xs) {
      min-width: calc(50% - 9px);
    }
    &>a {
      text-align: center;
      font-size: 21px;
      font-weight: 600;
      padding: 23.5px 10px;
      display: block;
      color: $white;
      background-color: $keyColor;
      transition: all $transition !important;
      @include mq-max(sm) {
        font-size: 18px;
      }
      &:hover {
        color: $white;
      }
    }

    //現在地
    &.is-active {
      border-bottom: none;
      &>a {
        background-color: $white;
        color: $black;
        border-left: $bdr-width-tab solid $bdrColor;
        border-right: $bdr-width-tab solid $bdrColor;
        position: relative;
        &:before {
          content: '';
          width: calc(100% + (#{$bdr-width-tab} * 2));
          height: 10px;
          display: inline-block;
          background-color: $keyColor;
          position: absolute;
          top: -10px;
          left: -1 * $bdr-width-tab;
        }
      }
    }
  }

  // &__body {}

  &__article {
    display: none;
    padding-top: 60px;
    &.is-show {
      display: block;
    }
  }

  &__section {
    position: relative;
    padding-bottom: 60px;
    &:last-child {
      padding-bottom: 0;
    }
  }

  //タブ内アンカーリンク(c-anchor調整用)
  &__anchor {
    padding: 20px;
    border: 1px solid $bdrColor;
    margin-bottom: 80px;
    @include mq-max(sm) {
      margin-bottom: 50px;
    }
    // &-list {}
    // &-item {}
    // &-link {}
  }
}