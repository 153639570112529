.l-navigation {
  display: flex;
  @include mq-max(md) {
    display: none;
    html.open &.active > ul {
      overflow-y: scroll;
      height: calc(100vh - #{$headerHeight-sp});//画面高さ - fixedヘッダーの高さ
      padding-bottom: 120px;
    }
  }
  &.active {
    transform: translateY(0%);
  }

  //採用サイト
  &--recruit{
    .l-navigation__inner{
      padding: 0;
    }
  }

  //menu.js制御用クラス
  &__jsInner {
    display: flex;
    @include mq-max(md) {
      flex-direction: column;
      height: calc(100vh - #{$headerHeight-sp});
      overflow-y: scroll;
    }
  }

  &__wrap {
    margin-right: 30px;
    @include mq-max(md) {
      margin-right: 0;
      display: flex;
      flex-direction: column-reverse;
      padding-top: 20px;
      background-color: $white;
    }
  }

  //お問い合わせ
  &__contact {
    &>a {
      background-color: $keyColor;
      color: $white;
      font-size: 10px;
      font-weight: 600;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0 13px;
      transition: all $transition;
      @include mq-max(md) {
        padding: 8px 10px;
      }
      &:before {
        @include fa('f0e0');
        font-weight: 400;
        font-size: 29px;
        line-height: 1;
      }
      &:hover {
        color: $white;
        opacity: .7;
      }
    }
  }

  //お問い合わせ
  &__recruit {
    &>a {
      background-color: #FF9700;
      color: $white;
      font-size: 10px;
      font-weight: 600;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0 13px;
      transition: all $transition;
      text-align: center;
      @include mq-max(md) {
        padding: 8px 10px;
      }
      &:before {
        @include fa('f406');
        font-size: 24px;
        line-height: 1.5;
      }
      &:hover {
        color: $white;
        opacity: .7;
      }
    }
  }

  //上Navi
  &__top {
    display: flex;
    justify-content: flex-end;
    @include mq-max(md) {
      background-color: $white;
      justify-content: center;
      width: 100%;
      padding: 8.5px 0;
    }
  }
  &__topItem {
    margin: 0 12.5px;
    @include mq-max(md) {
      margin: 0;
      padding: 0 18.5px;
      border-right: 1px solid $bdrColor;
      width: 100%;
      &:last-child {
        border-right: none;
      }
    }
    &>a {
      color: $black;
      font-size: 15px;
      display: flex;
      align-items: center;
      padding: 14px 0 5.9px;
      @include mq-max(md) {
        padding: 11.5px 0;
        justify-content: center;
      }
      &:before {
        content: '';
        margin-right: 8px;
        color: $keyColor;
      }
    }
    &--recruit {
      &>a {
        &:before {
          @include fa('f406');
        }
      }
    }
    &--eng {
      &>a {
        &:before {
          @include fa('f0ac');
        }
      }
    }
  }

  //下Navi
  &__inner {
    padding: 43px 0 0 0;
    // padding: 0;
    display: flex;
    @include mq-max(md) {
      display: block;
    }
  }
  &__item {
    // position: relative;
    &:last-child {
      margin-right: 0;
    }
    @include mq-max(md) {
      margin-right: 0;
      border-top: 1px solid $gray;
      &:first-child {
        border-top: none;
      }
      &:last-child{
        border-bottom: 1px solid $gray;
      }
    }
    a {
      @include mq-max(md) {
        background-color: $white;
      }
    }
    &>a {
      text-decoration: none;
      font-size: 15px;
      font-weight: 600;
      color: $textColor;
      display: inline-block;
      padding: 0px 14px 16.7px;
      position: relative;
      @include mq-max(md) {
        display: block;
        padding: 12px 50px 12px 28px;
      }
      &:before {
        content: '';
        width: 0%;
        height: 2px;
        display: inline-block;
        background-color: $keyColor;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: all $transition;
      }
      &:after {
        @include mq-max(md) {
          @include fa('f105');
          font-size: 23px;
          position: absolute;
          top: 50%;
          right: 27.5px;
          transform: translateY(-50%);
          color: $keyColor;
        }
      }
      &:hover {
        color: $keyColor;
        &:before {
          width: 100%;
          @include mq-max(md) {
            display: none;
          }
        }
      }

      //現在地
      &.current {
        &:before {
          width: 100%;
          @include mq-max(md) {
            display: none;
          }
        }
      }
    }

    //下層メニュー
    &.has-dropdown {
      &>a{
        position: relative;
        &:after {
          @include fa('f107');
          color: $keyColor;
          margin-left: 4px;
          line-height: 1;
          @include mq-max(md) {
            @include fa('f067');
            font-size: 15px;
          }
        }
        &.has-dropdown {
          &:after {
            @include mq-max(md) {
              content: '\f068';
            }
          }
        }
      }

      // クリック時
      &.is-opened {
        &>a {
          &:before {
            width: 100%;
          }
          &:after {
            @include fa('f106');
            @include mq-max(md) {
              @include fa('f068');
            }
          }
        }
        .l-dropDown {
          top: $headerHeight; // headerの高さ
          opacity: 1;
          visibility: visible;
          @include mq-max(md) {
            top: auto;
            opacity: 1;
            display: block;
          }
          &>li {
            // border-bottom: 1px solid $gray;
            // &:first-child {
            //   border-top: 1px solid $gray;
            // }
            // &:last-child {
            //   border-bottom: none;
            // }
          }
          a {
            transition: color $transition,
                        height $transition;
            &:hover {
              color: $keyColor;
            }
            @include mq-max(md) {
              opacity: 1;
              line-height: 1.5;
              height: 48px;
              padding: 15px 10px 15px 25px;
              overflow: hidden;
            }
          }

          &__block {
            &-item {
              @include mq-max(md) {
                padding: 13px 45px 13px 45px !important;
              }
              &--all {}
              &--parent {}
              &--child {
                @include mq-max(md) {
                  height: 30px !important;
                  padding: 4px 25px 4px 60px !important;
                }
              }
            }
            &-list {
              &--child {
                @include mq-max(md) {
                  margin: 0 0 15px;
                }
              }
            }
          }
        }
      }

      //開閉前
      .l-dropDown {
        position: absolute;
        top: $headerHeight - 30px; // headerの高さ - 30px
        left: 0;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        transition: all .5s ease;
        z-index: -1;
        overflow: hidden;
        background-color: $gray-l;
        display: flex;
        justify-content: center;
        padding: 70px $contentPadding/2;
        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
        @include mq-max(md) {
          display: block;
          padding: 0;
          filter: none;
          position: relative;
          transition: none;
          top: auto;
          opacity: 1;
          visibility: visible;
          z-index: 1;
        }

        &__block {
          max-width: 345px;
          width: 100%;
          margin-right: 20px;
          @include mq-max(md) {
            max-width: 100%;
            padding: 0;
          }
          &:last-child {
            margin-right: 0;
            @include mq-max(md) {
              border-bottom: none;
            }
            .l-dropDown__block-inner {
              border-bottom: none;
            }
          }
          &--all {
            max-width: 355px;
            padding-right: 40px;
            margin-right: 40px;
            border-right: 1px solid $bdrColor;
            @include mq-max(md) {
              max-width: 100%;
              padding: 0;
              margin: 0;
              border-right: none;
            }
          }
          &-inner {
            @include mq-max(md) {
              border-bottom: 1px solid $white;
            }
            //パーツ調整
            .c-topHeading {
              @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
                font-size: 60px;
              }
              @include mq-max(md) {
                display: none;
              }
            }
          }
          //ボタン共通
          &-item {
            width: 100%;
            position: relative;
            padding-right: 40px;
            font-size: 15px;
            font-weight: 600;
            box-shadow: $box-shadow;
            @include mq-max(md) {
              background-color: $gray-l !important;
              box-shadow: none;
            }
            &:after {
              @include fa('f061');
              line-height: 1;
              color: $keyColor;
              position: absolute;
              top: 50%;
              right: 16px;
              transform: translateY(-50%);
              transition: all $transition;
              @include mq-max(md) {
                right: 27.5px;
              }
            }
            &:hover {
              &:after {
                right: 10px;
                @include mq-max(md) {
                  right: 27.5px;
                }
              }
            }
            //扉ページ用
            &--all {
              padding: 10px 38px;
              &:before {
                @include fa('f03a');
                color: $keyColor;
                line-height: 1;
                position: absolute;
                top: 50%;
                left: 14px;
                transform: translateY(-50%);
                @include mq-max(md) {
                  content: none;
                }
              }
              &:after {
                @include fa('f105');
              }
            }
            //親ボタン(サムネ付き)
            &--parent {
              display: flex !important;
              align-items: center;
              &:after {
                @include fa('f061');
                @include mq-max(md) {
                  @include fa('f105');
                }
              }
              &>span {
                display: block;
              }
            }
            // 子ボタン
            &--child {
              padding: 9px 40px 9px 16px;
              &:before {
                @include mq-max(md) {
                  content: '';
                  width: 5px;
                  height: 5px;
                  display: inline-block;
                  background-color: $keyColor;
                  position: absolute;
                  top: 52%;
                  left: 45px;
                  transform: translateY(-50%);
                }
              }
              &:after {
                @include fa('f105');
                @include mq-max(md) {
                  content: none;
                }
              }
            }
          }
          &-list {
            &>li {
              margin-bottom: 18px;
              @include mq-max(md) {
                margin-bottom: 0;
                border-bottom: 1px solid $white;
              }
              &:last-child {
                margin-bottom: 0;
                @include mq-max(md) {
                  border-bottom: none;
                }
              }
            }
            &--child {
              display: flex;
              flex-wrap: wrap;
              margin: 18px -5px 0;
              @include mq-max(md) {
                display: block;
                margin: 0;
              }
              &>li {
                width: 50%;
                padding: 0 5px 10px;
                @include mq-max(md) {
                  width: 100%;
                  padding: 0;
                }
              }
              //縦並び
              &--column {
                &>li {
                  width: 100%;
                  padding-bottom: 12px;
                  @include mq-max(md) {
                    padding-bottom: 0;
                  }
                }
              }
            }
          }
          //親リンクサムネイル
          &-thumb {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 90px;
            height: 90px;
            margin-right: 25px;
            flex-shrink: 0;
            @include mq-max(md) {
              display: none !important;
            }
          }
          &-body {
            flex-grow: 1;
            white-space: normal;
          }
        }

        li {
          // border-bottom: 1px solid $gray;
          // @include mq-max(md) {
          //   border-bottom: none;
          // }
          // &:last-child {
          //   border: none;
          // }
          a {
            background-color: $white;
            // padding: 10px 15px;
            white-space: nowrap;
            display: block;
            text-decoration: none;
            color: $textColor;
            width: 100%;
            @include mq-max(md) {
              line-height: 0;
              height: 0;
              padding: 0;
              opacity: 0;
              transition: height $transition,
                          opacity 0.4s,
                          padding $transition,
                          line-height $transition;
            }
          }
        }
      }
    }
  }
}
