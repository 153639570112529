//サイトレイアウト構造を記述

#container {}
#main {}
#contents {
  padding: 0;
  @include mq-max(md) {
    width: $full-size;
    padding: $sectionPadding 0 0;
  }
}

//gridlex調整
[class*="grid"] {
  margin-bottom: -1 * $contentPadding; //gridlexを使うことでできてしまう下余白を相殺。
}
[class*="col-"] {
  img {
    width: 100%;
    display: block;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  基本ブロックレイアウト

///////////////////////////////////////////////////////////////////////////////////////////////////////
.l-section {
  width: 100%;
  max-width: $contentWidth;
  margin: 0 auto;
  padding: $sectionPadding 0;
  @include mq-max(content) {
    padding: $sectionPadding $contentPadding;
  }
  @include mq-max(md) {
    padding: 0 $contentPadding $sectionPadding;
  }
  @include mq-max(sm) {
    padding: 0 $contentPadding/2 $sectionPadding;//お好みで変更
  }
  &__inner {
    padding: 0 0 $sectionInnerPadding;//お好みで変更
    &:last-child {
      padding-bottom: 0;
    }
  }

  //下層用
  &--lower {
    max-width: $contentWidth-lower;
    @include mq-max(content) {
      padding: $sectionPadding 0;
    }
    @include mq-max(content-lower) {
      padding: $sectionPadding $contentPadding;
    }
    @include mq-max(md) {
      padding: 0 $contentPadding $sectionPadding;
    }
    @include mq-max(sm) {
      padding: 0 $contentPadding/2 $sectionPadding;//お好みで変更
    }
  }

  //横幅調整用
  &--full {
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
  &--medium {
    max-width: 800px;
    margin: 0 auto;
    @include mq-max(xs) {
      max-width: 100%;
    }
  }
  &--small {
    max-width: 600px;
    margin: 0 auto;
    @include mq-max(xs) {
      max-width: 100%;
    }
  }

  //コンテンツ下とフッターとの間隔調整用
  &--bottom {
    padding-bottom: 120px;
    @include mq-max(sm) {
      padding-bottom: 80px;
    }
  }

  //grid調整(デフォで付く下余白をマージンで相殺)
  // [class*="grid"] {
  //   margin-bottom: -1 * $contentPadding;
  // }
}

//background共通設定
@mixin bg_scss() {
  // @include mq-max(content) {}
  @include mq-max(md) {
    padding-top: $sectionPadding;
    margin: 0 0 $sectionPadding;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

//背景色（.l-sectionを囲って使う）
.l-bg {
  @include bg_scss();
  &--keyColor {
    background-color: $keyColor;
  }
  &--lightGray {
    background-color: $gray-l;
  }
  &--steal {
    background: url('/inc/image/common/bg/bg_steal.jpg')no-repeat center / cover;
  }
}


///////////////////////////////////////////////////////////////////////////////////////////////////////

//  text-align

///////////////////////////////////////////////////////////////////////////////////////////////////////
$breakpoint-value : xs,sm,md,lg;

.u-taL {
  text-align: left !important;
  @each $breakpoint in $breakpoint-value {
    &--#{$breakpoint} {
      &-taC {
        @include mq-max(#{$breakpoint}) {
          text-align: center !important; //Centerに切り替え
        }
      }
    }
  }
}
.u-taC {
  text-align: center !important;
  //「$breakpoint-value」で設定したブレイクポイント配列でクラスを作成。
  //例：.u-taC--xs-taL
  @each $breakpoint in $breakpoint-value {
    &--#{$breakpoint} {
      &-taL {
        @include mq-max(#{$breakpoint}) {
          text-align: left !important; //leftに切り替え
        }
      }
    }
  }
}
.u-taR {
  text-align: right !important;
  @each $breakpoint in $breakpoint-value {
    &--#{$breakpoint} {
      &-taC {
        @include mq-max(#{$breakpoint}) {
          text-align: center !important; //Centerに切り替え
        }
      }
    }
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  only系

///////////////////////////////////////////////////////////////////////////////////////////////////////
.u-only-xs {
  display: none !important;
  @include mq-max(xs) {
    display: block !important;
  }
}
.u-only-sm {
  display: none !important;
  @include mq-max(sm) {
    display: block !important;
  }
  @include mq-max(xs) {
    display: none !important;
  }
}
.u-only-md {
  display: none !important;
  @include mq-max(md) {
    display: block !important;
  }
  @include mq-max(sm) {
    display: none !important;
  }
}
.u-only-lg {
  @include mq-max(md) {
    display: none !important;
  }
}

.u-not-under-xs {
  @include mq-max(xs) {
    display: none !important;
  }
}
.u-not-under-sm {
  @include mq-max(sm) {
    display: none !important;
  }
}
.u-not-under-md {
  @include mq-max(md) {
    display: none !important;
  }
}
.u-not-under-lg {
  @include mq-max(lg) {
    display: none !important;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ページ内アンカー位置調整

///////////////////////////////////////////////////////////////////////////////////////////////////////
.l-anchorRepo {
  @include mq-max(md) {
    padding-top: $headerHeight-sp;
    margin-top: -1 * $headerHeight-sp;
  }
}

.l-anchorStrength {
  padding-top: $headerHeight;
  margin-top: -1 * $headerHeight;
  @include mq-max(md) {
    padding-top: $headerHeight-sp;
    margin-top: -1 * $headerHeight-sp;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  layout

///////////////////////////////////////////////////////////////////////////////////////////////////////

//互い違い
.l-alternate {
  &__wrap {
    margin-bottom: 90px;
    @include mq-max(xs) {
      margin-bottom: 60px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__img {
    @include mq-max(xs) {
      margin-bottom: -15px;
    }
  }
  &__desc {}
}
