///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ボタン

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-btn {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  padding: 28px 57px 28px 33px;
  color: $white;
  background-color: $keyColor;
  line-height: 1.25;
  border: 2px solid $keyColor;
  max-width: 100%;
  margin-bottom: 30px;
  transition: color $transition,
  background-color $transition;
  @include mq-max(xs) {
    width: 100%;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    color: $keyColor;
    background-color: $white;
    &:after {
      right: 25px;
    }
  }
  &:after {
    @include fa('f061');
    line-height: 1;
    position: absolute;
    top: 52%;
    right: 33px;
    transform: translateY(-50%);
    transition: right $transition;
  }

  //PDF
  &[href$=".pdf"] {
    &:hover {
      &:after {
        right: 33px;
        color: $keyColor;
      }
    }
  }

  //外部リンク
  &[target="_blank"] {
    &:after {
      color: $white;
    }
    &:hover {
      &:after {
        right: 33px;
        color: $keyColor;
      }
    }
  }

  //小さいボタン
  &--small {
    padding: 11.5px 37px 11.5px 20px;
    font-size: 14px;
    &:after {
      right: 20px;
    }
    &:hover {
      &:after {
        right: 15px;
      }
    }
  }

  &--ico-list {
    &:after {
      @include fa('f03a');
    }
    &:hover {
      &:after {
        right: 33px;
      }
    }
  }

}