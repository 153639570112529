// CV contactエリア

.c-cvContact {
  background: url('/inc/image/common/bg/bg_contact.jpg')no-repeat center / cover;
  &__inner {
    padding: 167px 0;
    max-width: $contentWidth;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mq-max(content) {
      padding: 100px $contentPadding;
      flex-wrap: wrap;
    }
    @include mq-max(md) {
      flex-direction: column;
      padding: 70px $contentPadding/2;
    }
  }
  &__heading {
    color: $white;
    text-align: center;
    margin: 0;
    @include mq-max(content) {
      width: 50%;
      margin-bottom: 40px;
    }
    @include mq-max(md) {
      width: 100%;
      margin-bottom: 35px;
    }
    @include mq-max(xs) {
      margin-bottom: 20px;
    }
    &>span {
      font-size: 90px;
      font-family: $roboto;
      font-style: italic;
      display: block;
      margin: 0;
      line-height: 1;
      letter-spacing: 0.06em;
      @include mq-max(sm) {
        font-size: 70px;
      }
    }
    &>small {
      display: inline-block;
      font-size: 22px;
      font-weight: 600;
      margin: 0;
      line-height: 1.45;
      letter-spacing: 0.15em;
    }
  }

  &__tel {
    color: $white;
    text-align: center;
    @include mq-max(content) {
      width: 50%;
      margin-bottom: 40px;
    }
    @include mq-max(md) {
      width: 100%;
      margin-bottom: 50px;
    }
    @include mq-max(xs) {
      margin-bottom: 30px;
    }
    &-heading {
      font-size: 28px;
      margin-bottom: 5px;
      letter-spacing: 0.24em;
      @include mq-max(sm) {
        font-size: 24px;
      }
    }
    &-num {
      font-size: 60px;
      font-weight: 600;
      font-family: $roboto;
      font-style: italic;
      margin-bottom: 4px;
      line-height: 1;
      letter-spacing: 0.06em;
      @include mq-max(sm) {
        font-size: 50px;
      }
    }
    &-term {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.06em;
      @include mq-max(sm) {
        font-size: 16px;
      }
    }
  }

  &__btn {
    max-width: 464px;
    width: 100%;
    @include mq-max(content) {
      margin: 0 auto;
    }
    &>a {
      display: block;
      color: $white;
      font-size: 26px;
      border: 6px solid $white;
      text-align: center;
      width: 100%;
      margin: 0 auto;
      padding: 43.5px 15px;
      letter-spacing: 0.06em;
      transition: all $transition;
      @include mq-max(sm) {
        font-size: 24px;
        padding: 30px 10px;
      }
      @include mq-max(xs) {
        font-size: 20px;
      }
      &:hover {
        color: $keyColor;
        background-color: $white;
      }
      &>span {
        display: flex;
        align-items: baseline;
        justify-content: center;
        &:before {
          @include fa('f0e0');
          margin-right: 5px;
        }
      }
    }
  }


  //採用
  &--recruit {
    background: url('/inc/image/common/bg/bg_recruit.jpg')no-repeat center / cover;
    position: relative;
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: $black;
      opacity: .5;
      position: absolute;
      top: 0;
      left: 0;
    }
    .c-cvContact {
      &__inner {
        padding-top: 165px;
        padding-bottom: 165px;
        position: relative;
        z-index: 2;
        @include mq-max(md) {
          padding-top: 80px;
          padding-bottom: 80px;
        }
      }
      &__heading {
        max-width: 749.5px;
        padding-left: 30px;
        padding-right: 30px;
        @include mq-max(md) {
          padding-left: 0;
          padding-right: 0;
          margin-bottom: 30px;
        }
        img {
          width: 100%;
          display: block;
          line-height: 1;
        }
      }
      &__btn {
        &>a {
          &:hover {
            color: $keyColor-recruit;
          }
          &>span {
            &:before {
              content: none;
            }
            &:after {
              @include fa('f061');
              line-height: 1;
              margin-left: 14px;
            }
          }
        }
      }
    }
  }
}
