//リンクパーツ

//装飾(aタグ内で使う)
.c-readmore {
  color: $keyColor;
  line-height: 1;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  position: relative;
  &:after {
    @include fa('f061');
    line-height: 1;
    position: absolute;
    top: 55%;
    right: -20px;
    transform: translateY(-50%);
    transition: all $transition;
  }

  //カードパーツ用
  &--stream {
    color: $textColor;
    padding-bottom: 5px;
    overflow: hidden;
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: $keyColor-recruit;
      position: absolute;
      bottom: 0;
      left: -100%;
    }
    &:after {
      content: '';
      width: 100%;
      height: 2px;
      background-color: $keyColor-recruit;
      top: auto;
      bottom: 0;
      left: 0;
      transform: none;
    }
  }
}

//大きいリンク
.c-sectionLink {
  &__wrap {
    margin-bottom: $sectionPadding;
  }
  &__item {
    overflow: hidden;
    position: relative;
    &:before,&:after {
      content: '';
      display: block;
      width: 100.5%;
      height: 100.5%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      pointer-events: none;
      transition: all $transition;
    }
    &:before {
      background-color: $black;
      opacity: .4;
      z-index: 2;
    }
    &:after {
      background: url('/inc/image/top/bg_network.jpg')no-repeat center / cover;
      z-index: 1;
    }
    &:hover {
      &:after {
        transform: translate(-50%,-50%) scale(1.1);
      }
      .c-readmore {
        &:after {
          right: -25px;
        }
      }
    }
    &>a {
      display: block;
      padding: 20px 40px;
      height: 447px;
      @include mq-max(md) {
        padding: 20px;
      }
      @include mq-max(sm) {
        height: 250px;
        padding: 20px $contentPadding/2;
      }
      @include mq-max(xs) {
        height: 200px;
      }
    }
    &--network {
      &:after {
        background: url('/inc/image/top/bg_network.jpg')no-repeat center / cover;
      }
    }
    &--company {
      &:after {
        background: url('/inc/image/top/bg_company.jpg')no-repeat center / cover;
      }
    }

    //パーツ調整
    .c-topHeading {
      margin-bottom: 0;
      position: relative;
      z-index: 3;
    }
    .c-readmore {
      color: $white;
      display: inline-block;
      position: absolute;
      z-index: 3;
      bottom: 22px;
      right: 53px;
      @include mq-max(sm) {
        right: 30px;
      }
    }
  }
}

//共通アンカーリンク
.c-anchor {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7.5px -15px;
  &__wrap {
    margin-bottom: 50px;
    padding: 30px 0;
    border-bottom: 1px solid $bdrColor;
    @include mq-max(content) {
      padding: 20px $contentPadding;
    }
    @include mq-max(sm) {
      padding: 20px $contentPadding/2;
    }
  }
  &__inner {
    max-width: $contentWidth-lower;
    margin: 0 auto;
  }
  &__item {
    padding: 0 7.5px 15px;
    @include mq-max(xs) {
      width: 50%;
    }
  }
  &__link {
    height: 100%;
    font-size: 16px;
    font-weight: 600;
    display: block;
    color: $textColor !important;
    transition: all $transition !important;
    box-shadow: $box-shadow;
    padding: 8px 40px 8px 18px;
    position: relative;
    @include mq-max(xs) {
      font-size: 12px;
      padding: 8px 28px 8px 10px;
    }
    &:after {
      @include fa('f107');
      font-size: 18px;
      color: $keyColor;
      line-height: 1;
      position: absolute;
      top: 52%;
      right: 18px;
      transform: translateY(-50%);
      @include mq-max(xs) {
        right: 12px;
      }
    }
    &:hover {
      color: $keyColor !important;
      box-shadow: $box-shadow-hover;
    }
  }
}


//写真付きラベルリンク
.c-labelLink {
  display: flex;
  margin: 0 -16px -32px;
  @include mq-max(md) {
    flex-direction: column;
  }
  &__item {
    width: 50%;
    padding: 0 16px 32px;
    @include mq-max(md) {
      width: 100%;
    }
    &>a {
      width: 100%;
      display: flex;
      &:hover {
        .c-labelLink__body {
          &:after {
            right: 8px;
          }
        }
      }
    }
  }
  &__thumb {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 223px;
    padding-top: (205 / 223 * 30%);
    flex-shrink: 0;
    @include mq-max(lg) {
      width: 150px;
    }
    @include mq-max(md) {
      width: 223px;
    }
    @include mq-max(sm) {
      width: 200px;
    }
    @include mq-max(xs) {
      width: 100px;
    }
  }
  &__body {
    background-color: $white;
    padding: 15px 52px 10px 30px;
    flex-grow: 1;
    width: calc(100% - 223px);
    color: $textColor;
    position: relative;
    @include mq-max(md) {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    &:after {
      @include fa('f061');
      position: absolute;
      top: 50%;
      right: 13px;
      transform: translateY(-50%);
      transition: all $transition;
      color: $keyColor-recruit;
    }
    //パーツ調整
    .c-topHeading {
      font-size: 50px;
      margin-bottom: 10px;
      @include mq-max(content) {
        font-size: 34px;
      }
      @include mq-max(xs) {
        font-size: 24px;
      }
    }
    p {
      line-height: 1.5;
      font-size: 14px;
    }
  }
}