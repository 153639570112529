.c-list {
  list-style-type: none;
  position: relative;
  padding-left: 1.5em;
  margin-bottom: 30px;
  line-height: 1.5;
  &:last-child {
    margin-bottom: 0;
  }

  &--nostyle {
    padding-left: 0;
  }

  //丸付き
  &--disc {
    & > .c-list__item {
      position: relative;
      &:before {
        content: '・';
        display: inline-block;
        color: $keyColor;
        position: absolute;
        top: -0.2em;
        left: -1em;
        font-size: 1.5em;
        line-height: 1.5;
        @media all and (-ms-high-contrast: none) {
          top: -0.1em;
        }
      }
    }
  }

  //四角付き
  &--rect {
    &>.c-list__item {
      position: relative;
      &:before {
        content: '';
        display: inline-block;
        width: 6px;
        height: 6px;
        background-color: $keyColor;
        position: absolute;
        top: 0.65em;
        left: -1em;
        line-height: 1;
      }
    }
    //大き目
    &--lg {
      &>.c-list__item {
        font-size: 18px;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  //チェック
  &--check {
    padding-left: 0;
    & > .c-list__item {
      position: relative;
      font-size: 20px;
      padding-left: 1.5em;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px dashed $bdrColor;
      @include mq-max(xs) {
        font-size: 16px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        @include fa('f058');
        color: $keyColor;
        position: absolute;
        top: 0.35em;
        left: 0.2em;
        line-height: 1;
      }
    }
  }

  //カウント
  &--count {
    counter-reset: count;
    & > .c-list__item {
      position: relative;
      padding-left: 0.5em;
      &:before{
        counter-increment: count;
        content:counter(count)".";
        position: absolute;
        left: -1.1em;
        color: $keyColor;
      }
    }
    &--brackets{
      counter-reset: count;
      & > .c-list__item {
        position: relative;
        &:before{
          counter-increment: count;
          content:"("counter(count)")";
          position: absolute;
          left: -1.5em;
          color: $keyColor;
        }
      }
    }
  }

  //注釈
  &--comment {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    & > .c-list__item {
      position: relative;
      &:before{
        content: '※';
        display: inline-block;
        position: absolute;
        left: -1.5em;
        @media all and (-ms-high-contrast: none) {
          top: 0.1em;
        }
      }
    }
    //番号付き注釈
    &-num {
      counter-reset: count;
      & > .c-list__item {
        position: relative;
        padding-left: 0.5em;
        &:before {
          counter-increment: count;
          content: '※'counter(count);
          position: absolute;
          left: -1.5em;
          @media all and (-ms-high-contrast: none) {
            top: 0;
          }
        }
      }
    }
  }
  &__item {
    &__dl {
      display: flex;
      dt {
        white-space: nowrap;
      }
      dd {
        margin-left: 1em;
      }
    }
  }
}


//テーブルリスト
.c-tblList {
  &__item {
    display: flex;
    @include mq-max(iPadPro-v) {
      flex-direction: column;
    }
    &:last-child {
      .c-tblList__title {
        border-bottom: 1px solid $keyColor;
        @include mq-max(iPadPro-v) {
          border-bottom: none;
        }
      }
      .c-tblList__body {
        border-bottom: 1px solid $bdrColor;
        @include mq-max(iPadPro-v) {
          border-bottom-color: $keyColor;
        }
      }
    }
  }
  &__title,
  &__body {
    padding: 30px 0 30px 32px;
    @include mq-max(iPadPro-v) {
      padding: 15px 20px;
    }
  }
  &__title {
    width: 192px;
    font-weight: 600;
    border-top: 1px solid $keyColor;
    display: flex;
    // align-items: center;
    @include mq-max(iPadPro-v) {
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
  &__body {
    width: calc( 100% - 192px);
    position: relative;
    flex-grow: 1;
    padding-right: 130px;
    border-top: 1px solid $bdrColor;
    @include mq-max(iPadPro-v) {
      width: 100%;
    }
    @include mq-max(sm) {
      padding-right: 20px;
    }
  }


  //必要な場合に
  //住所
  &__info {
    @include mq-max(xs) {
      margin-bottom: 10px;
    }
    &-zip {
      margin-right: 1em;
      @include mq-max(xs) {
        margin-right: 0;
        display: block;
      }
    }
    &-address {
      @include mq-max(xs) {
        display: block;
      }
    }
    &-telfax {
      display: block;
    }
  }
  //電話番号
  &__tel {
    @include mq-max(sm) {
      margin-bottom: 10px;
    }
    &-number {
      @include mq-max(xs) {
        display: block;
      }
      &:after {
        content: '/';
        display: inline-block;
        margin-left: .5em;
        margin-right: .3em;
        @include mq-max(xs) {
          content: none;
        }
      }
    }
    &-fax {
      @include mq-max(xs) {
        display: block;
      }
    }
  }
  //ボタン
  &__btn {
    position: absolute;
    top: 24px;
    right: 0;
    @include mq-max(md) {
      position: static;
      text-align: center;
      margin-bottom: 30px;
    }
    .c-btn {
      @include mq-max(xs) {
        width: 100%;
        padding: 11.5px 20px;
      }
      &:after {
        @include fa('f03a');
        @include mq-max(xs) {
          position: static;
          margin-left: 5px;
        }
      }
      &:hover {
        &:after {
          right: 20px;
        }
      }
    }
  }

  //パーツ調整
  .c-readmore {
    position: absolute;
    top: 50%;
    right: 22px;
    transform: translateY(-50%);
    @include mq-max(sm) {
      position: relative;
      top: 0;
      right: auto;
      transform: none;
    }
  }

  &--recruit {
    .c-tblList {
      &__title {
        border-color: $keyColor-recruit !important;
      }
      &__item {
        &:last-child {
          .c-tblList__body {
            @include mq-max(iPadPro-v) {
              border-bottom-color: $keyColor-recruit
            }
          }
        }
      }
    }

    //パーツ調整
    .c-readmore {
      color: $keyColor-recruit;
      &:hover {
        color: darken($keyColor-recruit, 10%);
      }
    }
  }
}
