
.c-box {
  margin-bottom: 60px;
  filter: drop-shadow($box-shadow);
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    box-shadow: $box-shadow;
  }
  &:last-child {
    margin-bottom: 0
  }

  &__inner {
    background: $white;
    padding: 30px 40px 50px;
    @include mq-max(xs) {
      padding-left: 18px;
      padding-right: 18px;
    }
  }

  &--clip {
    .c-box__inner {
      clip-path: $commonClip;
      @include mq-max(xs) {
        clip-path: $commonClip-xs;
      }
    }
  }
}


.c-panel {
  border: 1px solid $bdrColor;
  padding: 20px 20px 15px;
  p {
    line-height: 1.5;
  }
}

