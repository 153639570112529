.c-label {
  display: inline-block;
  padding: 4px;
  margin-right: 4px;
  color: $white;
  background-color: $keyColor;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid $keyColor;

  $category_color:(
    aaa: $keyColor,
    bbb: #5B8E7D,
    ccc:  #B1CF5F,
    ddd:  #F4A259,
    eee: #BC4B51,
  );

  @each $name, $color in $category_color {
    &.c-label__#{$name} {
     background-color: $color;
     border: 1px solid $color;
    }
  }
}
